import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Auth0Client} from "@auth0/auth0-spa-js";
import {cookieStorageCache} from "./cache";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const loggedInEvent = new Event("auth0LoggedIn")
export const ele = document.createElement('div')

export const auth0 = new Auth0Client({
    domain: 'dev-jzknjgpwuxtzqtt1.us.auth0.com',
    clientId: 'bHONC6sdk1qM8Rrd4in0pwNiH78aDzgT',
    useRefreshTokens: false,
    // cache: cookieStorageCache,
    authorizationParams: {
        // redirect_uri: 'https://app.toddb.dev',
        redirect_uri: 'https://login.toddb.dev',
        // scope: 'openid profile'
        audience: 'https://dev-jzknjgpwuxtzqtt1.us.auth0.com/api/v2/',
    },
})


let globalIsLoggedIn = false
export const useIsLoggedIn = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(globalIsLoggedIn)

    useEffect(() => {
        async function event() {
            setIsLoggedIn(true)
        }
        ele.addEventListener('auth0LoggedIn', event)
        return () => { ele.removeEventListener('auth0LoggedIn', event) }
    }, []);

    return isLoggedIn
}
const globalSetIsLoggedIn = () => {
    globalIsLoggedIn = true
    ele.dispatchEvent(loggedInEvent)
}

window.addEventListener('load', async () => {
    try {
        const redirectResult = await auth0.handleRedirectCallback()
        console.log(redirectResult)

        const user = await auth0.getUser()
        console.log(window.location.origin, user)
        if (window.location.origin !== 'https://app.toddb.dev') {
            // window.location.href = 'https://app.toddb.dev'
        }
        globalSetIsLoggedIn()
    } catch (e) {
        console.log(e)
    }
})

try {
    await auth0.getTokenSilently()
    if (window.location.origin !== 'https://app.toddb.dev') {
        // window.location.href = 'https://app.toddb.dev'
    }
    globalSetIsLoggedIn()
} catch (error) {
    // @ts-ignore
        // throw error;
    console.log(error)

    // if (window.location.origin === 'https://app.toddb.dev') {
    //     await auth0.loginWithRedirect(
    //         {
    //         }
    //     )
    // }
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
