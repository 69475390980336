import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {auth0, ele, useIsLoggedIn} from "./index";

function App() {
  const isAuthenticated = useIsLoggedIn()

  const clickToLogin = async () => {
    await auth0.loginWithRedirect()
  }

  const clickToLogout = async () => {
    await auth0.logout({
      logoutParams: {
        returnTo: 'https://login.toddb.dev'
      }
    })
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {isAuthenticated ?
            (
                <div>
                  <div>You're logged in!</div>
                </div>
            )
            : <button id="login" onClick={clickToLogin}>Click to Login</button>
        }
        <button id="logout" onClick={clickToLogout}>Logout</button>
      </header>
    </div>
  );
}

export default App;

const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  useEffect(() => {
    ele.addEventListener('auth0LoggedIn', async () => {
      setIsAuthenticated(true)
    })
  }, []);

  return isAuthenticated
}
